import React, { useEffect, useState } from "react";
import { CiPillsBottle1, CiStickyNote } from "react-icons/ci";
import { FaSignature } from "react-icons/fa";
import { GiMedicalThermometer } from "react-icons/gi";
import { HiOutlineDocumentAdd, HiOutlineTrash } from "react-icons/hi";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { TbMedicalCross } from "react-icons/tb";
import Swal from "sweetalert2";
import useHttpService from "../../../hooks/useHttpService";
import * as S from "./styles";
import ProductPrescriptions from "../../../components/ProductPrescriptions";
import { usePrescription } from "../../../providers/PrescriptionContext";

import { config } from '../../../config';

const PrescriptionIcon = "/assets/icons/prescriptions.svg";

const PrescriptionGenerator = ({ appointment, products }) => {
  const { data, handleRequest, loading } = useHttpService(
    `/dashboard/create-receipt/${appointment}?skipCa=${!config.DIGITAL_CERTIFICATION_REQUIRED}`
  );
  const { hasPrescripted, setHasPrescripted } = usePrescription();
  const [selectedProduct, setSelectedProduct] = useState([]);
  const hasSelectedsProducts = selectedProduct?.length > 0;

  const productsCategoriesList = Object.entries(products).map(([_, item]) => item[0])

  const [activeIndex, setActiveIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const validateForm = () => {
    let text = "";
    let valid = true;

    const filterValidate = (propertie) => selectedProduct.filter(item => item[propertie])?.length === selectedProduct.length;

    const validateUsage = filterValidate("use");
    const validateInfo = filterValidate("info");
    const validateQuantity = filterValidate("quantity");

    const validateAll = !validateUsage || !validateInfo || !validateQuantity;

    if (!hasSelectedsProducts) {
      text = "Selecione um produto";
      valid = false;
    }

    if (validateAll) {
      text = "Preencha o uso, frequência e quantidade";
      valid = false;
    }
    return {
      valid,
      text,
    };
  };

  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: "Aguarde",
        text: "Estamos gerando sua prescrição...",
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
      if (data?.length) {
        setHasPrescripted(true);

        Swal.fire({
          title: "Prescrição gerada",
          text: "Deseja imprimi-la agora?",
          icon: "success",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Imprimir",
          cancelButtonText: "Não, obrigado",
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(data);
          }
          Swal.close();
        });
      }
    }
  }, [loading, data]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    selectedProduct && setSelectedProduct(null);
  };

  const handlePrescriptionSubmit = async () => {
    const { text, valid } = validateForm();
    if (!valid) {
      await Swal.fire({
        title: "Oops!",
        text,
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "Ok",
      });
      return;
    }
    handleRequest("post", { prescription_items: selectedProduct });
  };

  const handleProductSelectForPrescription = (product) => {
    const verifySelectedsProducts = selectedProduct?.find(
      (item) => item.productId === product.id
    );

    const {
      id,
      commercialName,
      category,
      nameWithConcentration,
      administration,
      screenings,
      volume
    } = product || {};

    const productDto = {
      productId: id,
      category,
      productName: nameWithConcentration,
      info: `${commercialName}, ${administration}. ${screenings?.dosage}`,
      use: administration,
      quantity: screenings?.volumeLimit || "",
      volume,
      observations: "",
    };

    const updateArray = !verifySelectedsProducts?.productId
      ? [...selectedProduct, productDto]
      : [...selectedProduct];
    setSelectedProduct(updateArray);

    if (selectedProduct.length === 0) {
      setActiveIndex(0);
    }
  };

  const handleChangeSpecificInfo = (id, field, value) => {
    const isValidQuantity = (value) => {
      const numberValue = Number(value);
      return (
        Number.isInteger(numberValue) &&
        !value.startsWith("0") &&
        numberValue <= 100
      );
    };

    if (field === "quantity" && !isValidQuantity(value)) {
      return;
    }

    const updatedItems = selectedProduct?.map((item) => {
      if (item.productId === id) {
        return { ...item, [field]: value };
      }

      return item;
    });

    setSelectedProduct(updatedItems);
  };

  const handleRemoveSelectedProduct = (product) => {
    setSelectedProduct(
      selectedProduct?.filter((item) => item.productId !== product.productId)
    );

    setActiveIndex(null);
  };

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      {" "}
      <S.ModalContent>
        <S.Title>
          <S.Icon src={PrescriptionIcon} alt="teste" />
          Realize a prescrição
        </S.Title>

        {hasSelectedsProducts &&
          selectedProduct.map((item, index) => {
            return (
              <>
                <S.CollapsibleButton onClick={() => handleItemClick(index)}>
                  {item.productName}
                </S.CollapsibleButton>
                <S.Collapsible open={activeIndex === index}>
                  <>
                    <S.PrescriptionSearch>
                      {!selectedProduct && (
                        <S.SearchWrapper>
                          <FaSignature />
                          <S.SearchInput
                            type="text"
                            placeholder="Prescrever Medicamento"
                            value={searchTerm}
                            onChange={handleSearchChange}
                          />
                        </S.SearchWrapper>
                      )}
                      {selectedProduct && (
                        <S.UsageOptions>
                          <S.UsageOption
                            onClick={() =>
                              handleChangeSpecificInfo(
                                item.productId,
                                "use",
                                "oral"
                              )
                            }
                            isActive={item.use === "oral"}
                          >
                            <GiMedicalThermometer />
                            Oral
                          </S.UsageOption>
                          <S.UsageOption
                            onClick={() =>
                              handleChangeSpecificInfo(
                                item.productId,
                                "use",
                                "topical"
                              )
                            }
                            isActive={item.use === "topical"}
                          >
                            <TbMedicalCross />
                            Topical
                          </S.UsageOption>
                        </S.UsageOptions>
                      )}

                      <S.InputWrapper>
                        <CiPillsBottle1 />
                        <S.RecomendationsField
                          placeholder="Ex: 1 comprimido a cada 8 horas"
                          value={item.info}
                          rows={4}
                          onChange={(e) =>
                            handleChangeSpecificInfo(
                              item.productId,
                              "info",
                              e.target.value
                            )
                          }
                        />{" "}
                      </S.InputWrapper>
                      <S.InputWrapper>
                        <CiStickyNote />

                        <S.ObservationsField
                          placeholder="Observações..."
                          value={item.observations}
                          onChange={(e) =>
                            handleChangeSpecificInfo(
                              item.productId,
                              "observations",
                              e.target.value
                            )
                          }
                        />
                      </S.InputWrapper>

                      <S.InputWrapper>
                        <AiOutlineFieldNumber />

                        <S.ObservationsField
                          placeholder="Quantidade"
                          value={item.quantity}
                          onChange={(e) =>
                            handleChangeSpecificInfo(
                              item.productId,
                              "quantity",
                              e.target.value
                            )
                          }
                        />
                      </S.InputWrapper>
                      {!hasPrescripted &&
                        <S.RemoveOption
                          onClick={() => handleRemoveSelectedProduct(item)}
                          firstIcon={selectedProduct.length > 1 && index === 0}
                        >
                          <S.RemoveLabel>Remover</S.RemoveLabel>
                          <HiOutlineTrash />
                        </S.RemoveOption>
                      }
                    </S.PrescriptionSearch>
                  </>
                </S.Collapsible>
              </>
            );
          })}

        {(hasSelectedsProducts && !hasPrescripted) && (
          <>
            <S.Actions>
              <S.Button disabled={loading} onClick={handlePrescriptionSubmit}>
                Realizar prescrição
                <HiOutlineDocumentAdd />
              </S.Button>
            </S.Actions>
          </>
        )}

        {!hasPrescripted && <ProductPrescriptions
          products={productsCategoriesList}
          handleProductSelectForPrescription={
            handleProductSelectForPrescription
          }
        />}
      </S.ModalContent>
    </>
  );
};

export default PrescriptionGenerator;