import React, { useState } from 'react';
import { CgLastpass } from 'react-icons/cg';
import { CiLogin } from 'react-icons/ci';
import { LuUser } from 'react-icons/lu';

import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { TOKEN_NAME } from '../../env';
import { httpService } from '../../services/axios';
import * as S from './styles';
import { isCaExpired } from '../../rules/isCaExpired';
import { config } from '../../config';
const logo = '/assets/logo.svg';

export function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      Swal.fire({
        title: 'Verificando credenciais...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      if (!email || !password) {
        throw new Error('Preencha todos os campos');
      }

      setLoading(true);
      const { data: response } = await httpService.post('/auth', {
        email,
        password,
      });

      if (!response.jwt) {
        throw new Error('Usuário e/ou senha incorreto.');
      }

      localStorage.setItem(TOKEN_NAME, response.jwt);

      const { data: responseSafeWeb } = await httpService.get('/users/safeweb-data');
      const { exists, data } = responseSafeWeb || { };

      const hasExpired = isCaExpired(data?.createdAt, data?.expires_in);

      if((exists && !hasExpired) || config.DIGITAL_CERTIFICATION_REQUIRED === false) {
        navigate('/home');
        localStorage.setItem("@bluemed-dashboard:ca-exists", true);
      }
      if(!exists || hasExpired) {
        navigate(`/settings`)
      }

      Swal.close();
    } catch (error) {
      Swal.hideLoading();
      Swal.fire('Oops!', 'Usuário e/ou senha incorreto.', 'warning');
    } finally {
      setLoading(false);
    }
  };

  return (
    <S.Body>
      <S.Header>
        <S.Logo src={logo} alt='Blue Med logo' />
      </S.Header>
      {/* <S.HeadingBox>
        <S.Title>Acesse agora sua conta</S.Title>
        <S.SubTitle>Dashboard do corpo médico</S.SubTitle>
      </S.HeadingBox> */}
      <S.Forms>
        <S.InputIconWrapper>
          <LuUser />

          <S.Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Usuário'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleLogin();
              }
            }}
          />
        </S.InputIconWrapper>
        <S.InputIconWrapper>
          <CgLastpass />

          <S.Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Senha'
            type='password'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleLogin();
              }
            }}
          />
        </S.InputIconWrapper>
        <S.SendButton onClick={handleLogin} disabled={loading}>
          <span>Acessar minha conta</span>
          <CiLogin />
        </S.SendButton>
      </S.Forms>

      <S.MedicalStaff>
        É um profissional da saúde e deseja fazer parte do nosso corpo médico?
        <br></br>
        <S.Link href='/cadastro-medico'>
          Cadastre-se agora mesmo e faça parte da nossa equipe, é rápido!
        </S.Link>
      </S.MedicalStaff>
    </S.Body>
  );
}

export default Login;
